<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="8"
          >所屬店家&nbsp;&nbsp;&nbsp;
          <el-select v-model="qStoreId" filterable>
            <el-option width="120"
              v-for="item in selectStores"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="8">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="車號" v-model="qCarNo">
            <template slot="prepend">車號</template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="引擎號碼" v-model="qEngineId">
            <template slot="prepend">引擎號碼</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:10px;">
        <el-col :span="8">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="ECO機碼" v-model="qEcoAuto">
            <template slot="prepend">ECO機碼</template>
          </el-input>
        </el-col>
        <el-col :span="8"
          >ECO機碼
          <el-select v-model="qIsEcoAuto">
            <el-option width="120" label="有/無" value=""></el-option>
            <el-option width="120" label="有" value="T"></el-option>
            <el-option width="120" label="無" value="F"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:10px;">
        <el-col :span="8"
          >車型&nbsp;&nbsp;&nbsp;
          <el-select v-model="qCatId" filterable>
            <el-option width="120"
              v-for="item in qCats"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="8"
          >狀態
          <el-select v-model="qStatus">
            <el-option width="120"
              v-for="item in qStatusList"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          車輛所屬
          <el-select v-model="qCarOwner">
            <el-option width="120"
              v-for="item in qCarOwnerList"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()" v-if="this.$store.getters.userType!='3'" >新增</el-button>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showProdsDistanceform()">編輯目前里程</el-button>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showProdsMileageform()">編輯合約里程</el-button>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="resetQuery">重置</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="carNo" label="車號" width="90%"></el-table-column>
        <el-table-column prop="carNoDisName" label="車輛編號" width="90%"></el-table-column>
        <el-table-column prop="storeId" label="店家" :formatter="fter_store" width="120%"></el-table-column>
        <el-table-column prop="prodCatId" label="車型" :formatter="fter_cat" width="120%"></el-table-column>
        <el-table-column prop="carOwner" label="車輛所屬" width="80%">
          <template slot-scope="scope">
            {{ displayCarOwner(scope.row.carOwner) }}
          </template> 
        </el-table-column>
        <el-table-column prop="status" label="狀態" width="80%">
          <!--:formatter="fter_status" :type="scope.row.statusType"-->
          <template slot-scope="scope">
            <el-tag :type="scope.row.statusType">
              {{ scope.row.status === "1" ? "啟用" : scope.row.status === "3" ? "維修" : "停用" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="statusChangeMsg" label="車輛維修/停用備註"></el-table-column>
        <el-table-column prop="contractMonthOfDate" align="right" label="每期結算日期"></el-table-column>
        <el-table-column prop="monthStartDistance" align="right" label="上期結算里程"></el-table-column>
        <el-table-column prop="nowDistance" align="right" label="目前里程"></el-table-column>
        <el-table-column prop="monthDistance" align="right" label="本期里程(計算值)"></el-table-column>
        <el-table-column prop="ableMileage" align="right" label="本期可使用里程(計算值)"></el-table-column>
        <el-table-column prop="contractMileage" align="right" label="合約里程"></el-table-column>
        <el-table-column prop="deductibleMileage" align="right" label="本期可折抵里程"></el-table-column>
        <el-table-column prop="buyDate" label="購買日期" width="90%"> </el-table-column>
        <el-table-column prop="birthday" label="出廠日期" width="90%"> </el-table-column>
        <el-table-column prop="ecoAuto" align="right" label="ECO機碼"></el-table-column>
        <el-table-column fixed="right" label="自取櫃操作" width="100">
          <template slot-scope="scope">
            <el-button v-show="scope.row.keyBoxDoorId != null" @click="showKeyBoxForm(scope.row,'get')" type="primary" size="small">取出</el-button>
            <el-button v-show="scope.row.keyBoxDoorId == null" @click="showKeyBoxForm(scope.row,'put')" type="primary" size="small">放入</el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <el-button @click="showDescribePicForm(scope.row)" type="danger" size="small">車況</el-button>
            <el-button @click="showPicDetailForm(scope.row)" type="warning" size="small">照片管理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal"
        :current-page.sync="pageCurrent"
        @current-change="query"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="編輯視窗" :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="editForm">
        <el-form-item label="商品ID" :label-width="formLabelWidth">
          <el-input v-model="form.prodId" autocomplete="off" readonly></el-input>
        </el-form-item>

        <el-form-item label="引擎號碼" :label-width="formLabelWidth" prop="engineId">
          <el-input v-model="form.engineId" autocomplete="off" :readonly="viewMode !== 'create'"></el-input>
        </el-form-item>

        <el-form-item label="店家" :label-width="formLabelWidth" prop="storeId">
          <el-select v-model="form.storeId" placeholder="店家">
            <el-option width="120"
              v-for="item in stores"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="車號" :label-width="formLabelWidth" prop="carNo">
          <el-input v-model="form.carNo" autocomplete="off" :readonly="viewMode !== 'create'" @blur="checkCarNo()"></el-input>
        </el-form-item>

        <el-form-item label="車輛編號" :label-width="formLabelWidth" prop="carNoDisName">
          <el-input v-model="form.carNoDisName" autocomplete="off"  @blur="checkCarNoDisName()"></el-input>
        </el-form-item>

        <el-form-item label="狀態" :label-width="formLabelWidth" prop="status">
          <!-- <el-select v-model="form.status" placeholder="狀態" disabled>
            <el-option width="120"
              v-for="item in statusList"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
            ></el-option>
          </el-select> -->
          <el-input v-model="statusLabel" style="width: 20%; margin-right: 12px;" readonly/>
          <el-button v-show="form.status != '1'" @click="showStatusChangeModel('1')" type="primary" size="small" round  :disabled="disabledButton">啟用</el-button>
          <el-button v-show="form.status != '3'" @click="showStatusChangeModel('3')" type="warning" size="small" round>維修</el-button>
          <el-button v-show="form.status != '0'" @click="showStatusChangeModel('0')" type="danger" size="small" round>停用</el-button>
        </el-form-item>
        <el-form-item label="車輛所屬" :label-width="formLabelWidth" prop="carOwner">
          <el-select v-model="form.carOwner" placeholder="車輛所屬">
            <el-option width="120" key="" label="未分配" value="" v-if="noCarOwner"></el-option>
            <el-option width="120" key="zocha" label="摩拓自有" value="zocha"></el-option>
            <el-option width="120" key="cooperate" label="合作門市" value="cooperate"></el-option>
            <el-option width="120" key="investor" label="投資人" value="investor"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="類別" :label-width="formLabelWidth" prop="ccType">
          <el-select v-model="form.ccType">
            <el-option width="120" key="S" label="輕型機車" value="S"></el-option>
            <el-option width="120" key="M" label="重型機車" value="M"></el-option>
            <el-option width="120" key="B" label="微型電動車" value="B"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="里程管理" :label-width="formLabelWidth">
          <el-switch
            v-model="form.enabledDistance"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="T"
            inactive-value="F">
          </el-switch>
        </el-form-item>

        <el-form-item label="車型" :label-width="formLabelWidth" prop="prodCatId">
          <el-select 
          filterable
          v-model="form.prodCatId" placeholder="車型">
            <el-option
              width="120"
              v-for="item in cats"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="顏色" :label-width="formLabelWidth">
          <el-input v-model="form.color" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="購買日期" :label-width="formLabelWidth">
          <el-date-picker v-model="form.buyDate" type="date" placeholder="">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="保險到期日" :label-width="formLabelWidth" prop="insuranceDueDate">
          <el-date-picker v-model="form.insuranceDueDate" type="date" placeholder="" :disabled="this.$store.getters.userType!='0'"></el-date-picker>
        </el-form-item>
        <el-form-item label="購買里程" :label-width="formLabelWidth" prop="buyDistance">
          <el-input v-model.number="form.buyDistance" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出廠日期" :label-width="formLabelWidth">
          <el-date-picker v-model="form.birthday" type="date" placeholder="">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="目前里程" :label-width="formLabelWidth" prop="nowDistance">
          <el-input v-model.number="form.nowDistance" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="本期起始里程" :label-width="formLabelWidth">
          <el-input v-model.number="form.monthStartDistance" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="每月內含里程" :label-width="formLabelWidth">
          <el-input v-model.number="form.contractMileage" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="本期可折抵里程" :label-width="formLabelWidth">
          <el-input v-model.number="form.deductibleMileage" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="每期里程結算日期(1~28)號" :label-width="formLabelWidth">
          <el-input v-model.number="form.contractMonthOfDate" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="本期可折抵里程" :label-width="formLabelWidth">
          <el-input v-model.number="form.deductibleMileage" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="" :label-width="formLabelWidth">
          <div class="remindText">* 如保養里程、保養周期、上次保養里程、上次保養周期為空，則無法使用保養提醒功能</div>
        </el-form-item>
        <el-form-item label="保養里程" :label-width="formLabelWidth">
          <el-input v-model.number="form.maintainDistance" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="保養周期" :label-width="formLabelWidth">
          <el-input v-model.number="form.maintainDays" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上次保養里程" :label-width="formLabelWidth">
          <el-input v-model.number="form.lastMaintainDistance" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上次保養周期" :label-width="formLabelWidth">
          <el-date-picker v-model="form.lastMaintainDate" type="date" placeholder="" format="yyyy-MM-dd" ></el-date-picker>
        </el-form-item>
        <el-form-item label="物聯網類型" :label-width="formLabelWidth">
          <el-select v-model="form.iotType" placeholder="物聯網類型" @change="changeIotType">
            <el-option label="無" :value="null"></el-option>
            <el-option label="ECO" value="ECO"></el-option>
            <el-option label="PURISM" value="PURISM"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="啟動無人自助" v-show="form.iotType == 'ECO'" :label-width="formLabelWidth">
          <el-switch
            v-model="form.enabledEco"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="T"
            inactive-value="F">
          </el-switch>
        </el-form-item>
        <!-- v-show="viewMode !== 'create'" -->
        <el-form-item label="物聯網機碼(ECO:11碼,Purism:10碼)" v-show="form.iotType !== null" :label-width="formLabelWidth">
          <el-input v-model="form.ecoAuto" autocomplete="off"  @blur="checkEcoRepat"></el-input>
          <el-button type="primary" v-show="form.iotType == 'ECO'" @click="openEcoSeat">測試開啟坐墊</el-button>
          <el-button type="warning" @click="getEcoGps">取得GPS座標</el-button>
          <el-button type="warning" @click="getFromStoreDistance">取得與門市距離</el-button>
        </el-form-item>
      </el-form>
      <div>
        <div v-for="(row, i) in prodStatus.slice(-5)" :key="i"> <!--最新五筆-->
          {{ row.updator }}在{{ row.updateDate }}描述:{{ row.content }}
        </div>
        <el-collapse>
        <el-collapse-item title="詳細資料" name="1">
          <div v-for="(row, i) in prodStatus" :key="i"> 
          {{ row.updator }}在{{ row.updateDate }}描述:{{ row.content }}
        </div>
        </el-collapse-item></el-collapse>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditStore" v-loading.fullscreen.lock="fullscreenLoading">確 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="照片設定" :close-on-click-modal="false" :visible.sync="dialogPicVisible">
      <el-row :gutter="20">
        客戶取車照片<a v-if="officeImg" target="_blank" :href="officeImg"
          >(開啟大圖)</a>
        <img style="width:100%; height:100%;" :src="officeImg" class="pic" />
        <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange" />
          <el-button type="primary"
            @click="uploadFile('officeImg')"
            v-loading.fullscreen.lock="fullscreenLoading"
            element-loading-background="rgba(255, 255, 255, 0.2)"
            >上傳</el-button>
        </form>
      </el-row>

      <el-row :gutter="20">
        行照正面<a v-if="pic1" target="_blank" :href="pic1">(開啟大圖)</a>
        <img style="width:100%; height:100%;" :src="pic1" class="pic" />
        <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange" />
          <el-button type="primary"
            @click="uploadFile('1')"
            v-loading.fullscreen.lock="fullscreenLoading"
            element-loading-background="rgba(255, 255, 255, 0.2)"
            >上傳</el-button>
        </form>
      </el-row>
      <el-row :gutter="20">
        行照反面<a v-if="pic2" target="_blank" :href="pic2">(開啟大圖)</a>
        <img style="width:100%; height:100%;" :src="pic2" class="pic" />
        <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange" />
          <el-button type="primary"
            @click="uploadFile('2')"
            v-loading.fullscreen.lock="fullscreenLoading"
            element-loading-background="rgba(255, 255, 255, 0.2)"
            >上傳</el-button>
        </form>
      </el-row>
      <el-row :gutter="20">
        強制險正面<a v-if="pic3" target="_blank" :href="pic3">(開啟大圖)</a>
        <img style="width:100%; height:100%;" :src="pic3" class="pic" />
        <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange" />
          <el-button type="primary"
            @click="uploadFile('3')"
            v-loading.fullscreen.lock="fullscreenLoading"
            element-loading-background="rgba(255, 255, 255, 0.2)"
            >上傳</el-button>
        </form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="backUploadDialog">退出</el-button>
      </div>
    </el-dialog>

    <el-dialog title="車況" :close-on-click-modal="false" :visible.sync="dialogDescribePicVisible">
      <el-row :gutter="20">
        車況<a v-if="describeImg" target="_blank" :href="describeImg">(開啟大圖)</a>
        <img style="width:100%; height:100%;" :src="describeImg" class="pic" />
        <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange" />
          <el-button type="primary"
            @click="uploadFile('describeImg')"
            v-loading.fullscreen.lock="fullscreenLoading"
            element-loading-background="rgba(255, 255, 255, 0.2)"
            >上傳</el-button>
        </form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogDescribePicVisible = false">退出</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量編輯目前里程視窗" :visible.sync="dialogProdsDistanceVisible"  width="90%"  :close-on-click-modal="false">
      <el-input type="textarea" placeholder="車號    目前里程  (請從excel複製車號與目前里程數值再貼上至此)" style="height:50vh"  rows="15" v-model="carNoDistanceInput"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogProdsDistanceVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmProdsDistance()">確 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批量編輯里程視窗" :visible.sync="dialogProdsMileageVisible"  width="90%"  :close-on-click-modal="false">
      <el-input type="textarea" placeholder="車號    每月內含里程    本期可折抵里程 (請從excel複製車號與合約里程數值再貼上至此)" style="height:50vh"  rows="15" v-model="carNoMileageInput"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogProdsMileageVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmProdsMileage()">確 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="智取櫃操作" :visible.sync="dialogKeyBoxVisible" width="90%" :close-on-click-modal="false">
      <el-row :gutter="20">
        車號：
        <el-input v-model="keyBox.carNo" autocomplete="off" readonly></el-input>
      </el-row>
      <el-row :gutter="20">
        <img :src="keyBox.qrCodeUrl" />
      </el-row>
      <el-row :gutter="20">
        <el-button type="warning" @click="getQRCodeForKeyBox()">重新產生QR Code</el-button>
        <el-button type="success" @click="confirmQRCodeScan()">掃描QR Code完成</el-button>
      </el-row>
      <el-row :gutter="20">
        <!-- 編號 -->
        門號
          <el-select v-model="keyBox.doorId" :disabled="keyBox.opType == 'get'">
            <el-option v-show="notEmptyDoors.indexOf(door) == -1" v-for="door in doors" :key="door" :value="door"></el-option>
          </el-select>
        <el-button type="primary" @click="keyBoxOpenDoor()">開啟</el-button>
      </el-row>
      
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogKeyBoxVisible = false">退出</el-button>
      </div>
    </el-dialog>

    <el-dialog title="狀態變更" :visible.sync="dialogStatusChageVisible" width="60%" :close-on-click-modal="false">
      <el-row :gutter="20">
        車號：
        <el-input v-model="form.carNo" autocomplete="off" readonly></el-input>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        {{statusChancgFormContent}}備註：
        <el-input v-model="statusForm.statusChangeMsg" autocomplete="off" ></el-input>
      </el-row>
      <el-row :gutter="20" style="width: 30%; margin-top: 50px">
        <label>輸入員工碼</label>
        <el-input v-model="statusForm.adminCheckCode" autocomplete="off"></el-input>
      </el-row>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogStatusChageVisible = false">退出</el-button>
        <el-button type="primary" @click="confirmStatusChange(statusForm.status, statusForm.statusChangeMsg)">確定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import imageCompression from "browser-image-compression";
export default {
  data() {
    return {
      formData: null,
      pageTotal: 0,
      pageCurrent: 1,
      cats: [],
      qCats: [],
      stores: [],
      prodPrices: [],
      catsMap: [],
      storesMap: [],
      selectStores: [],
      statusList: [
        { code: "1", disName: "啟用" },
        { code: "0", disName: "停用" },
        { code: "3", disName: "維修" }
      ],
      qStatusList:[
        { code: '', disName:"全部"},
        { code: "1", disName: "啟用" },
        { code: "0", disName: "停用" },
        { code: "3", disName: "維修" }
      ],
      qCarOwnerList:[
        { code:'', disName:"全部"},
        { code: "zocha", disName: "摩拓自有" },
        { code: "cooperate", disName: "合作門市" },
        { code: "investor", disName: "投資人" }
      ],
      rules: {
        carNo: [{ required: true, message: "不得為空值" }],
        engineId: [{ required: true, message: "不得為空值" }],
        storeId: [{ required: true, message: "不得為空值" }],
        carNoDisName: [{ required: true, message: "不得為空值" }],
        status: [{ required: true, message: "不得為空值" }],
        prodCatId: [{ required: true, message: "不得為空值" }],
        ccType: [{ required: true, message: "不得為空值" }],
        insuranceDueDate: [
          { required: true, message: "不得為空值", trigger:'blur' }
        ],
        nowDistance: [
          { required: true, message: "不得為空值" },
          { type: "integer", message: "必須為數值" }
        ],
        buyDistance: [
          { required: true, message: "不得為空值" },
          { type: "integer", message: "必須為數值" }
        ],
        monthStartDistance: [
          { required: true, message: "不得為空值" },
          { type: "integer", message: "必須為數值" }
        ],
        contractMonthOfDate: [
          { required: true, message: "不得為空值" },
          { type: "integer", message: "必須為數值" }
        ]
      },
      labelPosition: "right",
      pic1: "",
      pic2: "",
      pic3: "",
      officeImg: "",
      describeImg: "",
      url1: "../api/prod/upload",
      url2: "../api/prod/upload",
      url3: "../api/prod/upload",
      urlOfficeImg: "../api/prod/upload",
      urlDescribeImg: "../api/prodDescribe/upload",
      qCarNo: "",
      qStoreId: "",
      qCatId: "",
      qEngineId: "",
      qEcoAuto: '',
      qIsEcoAuto: '',
      qProdId: "",
      qStatus:'',
      qCarOwner:'',
      tableData: [],
      dialogFormVisible: false,
      dialogAuthVisible: false,
      dialogPicVisible: false,
      pic1ViewDialog: false,
      dialogDescribePicVisible: false,
      prodStatus: [], // 車況描述
      form: {
        enabledEco:'F',
        insuranceDueDate: "",
        buyDate: "",
        birthday: "",
        prodCatId: "",
        storeId: "",
        carNo: "",
        prodId: "",
        carOwner: "",
        status:"",
        monthStartDistance: 0,
        contractMonthOfDate: 0,
        engineId: "",
        color: "",
        newStatus: "",
        enabledDistance: "T",
        ccType: "M",
        carNoDisName: '',
        ecoAuto:null,
        maintainDistance: '',
        maintainDays: '',
        lastMaintainDistance: '',
        lastMaintainDate: null,
        storeId_: '',
        iotType: null
      },
      formLabelWidth: "120px",
      adminUsers: [
        { label: "132", key: "321" }
      ],
      viewMode: "create",
      adminUsersValue: [],
      fullscreenLoading: false,
      imageOption: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      },
      dialogProdsDistanceVisible: false,
      dialogProdsMileageVisible:false,
      carNoDistanceInput: "",
      carNoMileageInput:'',
      carNoList: [],
      dialogKeyBoxVisible: false,
      keyBox: {
        carNo: '',
        prodId: '',
        clientId: '',
        doorId: 1,
        step: 'qrcode',
        qrCodeUrl: null,
        opType: 'put',
        storeId: ''
      },
      doors:[1,2,3,4,5,6,7,8,9,10],
      notEmptyDoors:[],
      isEcoProd: 'F',
      dialogStatusChageVisible: false,
      statusChancgFormContent: '',
      statusForm:{
        statusChangeMsg: '',
        status: '1',
        adminCheckCode:''
      },
      disabledButton: false
    };
  },

  created() {
    this.fullscreenLoading = true
    if (this.$route.query.carNo != undefined) {
      this.qCarNo = this.$route.query.carNo;
    }
    if (this.$route.query.prodId != undefined) {
      this.qProdId = this.$route.query.prodId;
    }
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        if (res.data.authStores.length > 1) {
          this.selectStores.push({ code: "", disName: "全部" });
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });
        this.qStoreId = this.selectStores[0].code;
        this.qCats.push({
          code: '',
          disName: "全部"
        });
        res.data.cats.forEach(e => {
          this.qCats.push(e);
          this.cats.push(e);
          this.catsMap[e.code] = e.disName;
        });
        this.qCatId = this.qCats[0].code;
        
        if(this.$route.query.qType == 'isMaintain') {
          this.qStoreId = this.$route.query.qStoreId
          this.qType = "isMaintain"
        }

        if(this.$route.query.qStoreId != undefined && this.$route.query.qStoreId != ''){
          this.qStoreId = parseInt(this.$route.query.qStoreId.split(','), 10)
        }
        this.fullscreenLoading = false
        this.query();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
        this.fullscreenLoading = false
        // console.log(res.response.status)
        // alert('login error')
      });
  },
  name: "AdminProd",
  components: {},
  computed: {
    disName: {
      get() {
        if (this.$store.newClientUser == null) return "xx";
        return this.$store.newClientUser.disName;
      }
    },
    phone: {
      get() {
        if (this.$store.newClientUser == null) return "xx";
        return this.$store.newClientUser.phone;
      }
    },
    statusLabel() {
      switch(this.form.status) {
        case '1':
          return '啟用';
        case '3':
          return '維修';
        case '0':
          return '停用';
        default:
          return ''
      }
    } 
  },
  methods: {
    showStatusChangeModel(status) {
      // if (status == '1') {
      //   // this.confirmStatusChange('1', '')
      //   this.dialogStatusChageVisible = true
      //   this.statusChancgFormContent = '啟用'
      // } else {
      //   this.dialogStatusChageVisible = true
      //   this.statusChancgFormContent = status == '0' ? '停用' : '維修'
      //   this.statusForm.statusChangeMsg = this.form.statusChangeMsg
      //   this.statusForm.status = status
      //   this.dialogFormVisible = false
      // }
      this.dialogStatusChageVisible = true
      this.statusChancgFormContent = status == '1' ? '啟用' : status == '0' ? '停用' : '維修'
      this.statusForm.statusChangeMsg = this.form.statusChangeMsg
      this.statusForm.status = status
    },
    confirmStatusChange (status, statusChangeMsg) {
      this.$confirm("是否確定變更狀態", "注意", {
        confirmButtonText: "確定",
        cancelButtonText: "取消"
      }).then(() => {
        this.fullscreenLoading = true 
        // 預檢查員工碼
        this.axios.get('../api/params/checkAdminCode',{ 
          params:{
            adminCheckCode: this.statusForm.adminCheckCode
          }
        }).then(res=>{
          if(res.data.status !== "success"){
            this.$alert(res.data.rsMsg, "結果", {confirmButtonText: "確定"});
            this.statusForm.adminCheckCode = ''
            this.fullscreenLoading = false
            return
          }
          this.statusForm.adminCheckCode = ''
          
          let params = { status: status , statusChangeMsg: statusChangeMsg, prodId: this.form.prodId }
          this.$axios.post('../api/prod/updateProdStatus', params)
            .then(res => {
              this.fullscreenLoading = false
              if (res.data.rs == 'success') {
                this.$alert('更新狀態成功', "結果", { confirmButtonText: "確定" });
                this.form.status = status;
                this.query()
                this.dialogFormVisible = true;
                this.dialogStatusChageVisible = false
              } else if (res.data.rs == 'warning') {
                this.form.status = status;
                this.query()
                this.dialogFormVisible = true;
                this.dialogStatusChageVisible = false
                this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
              } else {
                this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
              }
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert("更新狀態失敗", "結果", { confirmButtonText: "確定" });
            })
        }).catch(() =>{
          this.$alert("檢查員工碼失敗", "結果", { confirmButtonText: "確定" })
          this.fullscreenLoading = false
        })
      })
    },
    checkEcoRepat(){
      this.fullscreenLoading = true
      this.$axios
        .get("../api/prod/checkEcoAuto?ecoAuto=" + this.form.ecoAuto, {})
        .then(rs => {
          if ((rs.data == 'EcoRepeat')) {
            this.$alert("ECO碼已重覆", "結果", { confirmButtonText: "確定" });
            this.form.ecoAuto = "";
          } 
          this.fullscreenLoading = false
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert("checkEco發生錯誤", "結果", { confirmButtonText: "確定" });
        });
    },
    getFromStoreDistance(){
      this.fullscreenLoading = true
      this.$axios
        .get("../api/prod/getFromStoreDistance", {
          params: {
            prodId: this.form.prodId,ecoAuto: this.form.ecoAuto, iotType: this.form.iotType
          }
        })
        .then(res => {
          this.fullscreenLoading = false
          this.$alert("車聯網連線測試完成" + res.data.rsMsg, "結果", { confirmButtonText: "確定" });
        })
        .catch(() => {
          this.fullscreenLoading = false
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
        });    },
    getEcoGps(){
      this.fullscreenLoading = true
      let params = { prodId: this.form.prodId,ecoAuto: this.form.ecoAuto, iotType: this.form.iotType }
      this.$axios.post('../api/prod/getEcoGps', params)
        .then((res) => {
          if (res.data.rs === 'success') {
            this.$alert("車聯網連線測試完成" + res.data.rsMsg, "結果", { confirmButtonText: "確定" });
          } else {
            this.$alert("車聯網連線失敗，請洽管理員", "結果", { confirmButtonText: "確定" });
          }
          this.fullscreenLoading = false
        })
    },
    openEcoSeat(){
      this.$confirm("是否確定開啟車廂", "注意", {
        confirmButtonText: "確定",
        cancelButtonText: "取消"
      }).then(() => {
        this.fullscreenLoading = true
        let params = { prodId: this.form.prodId,ecoAuto: this.form.ecoAuto,isEcoProd: this.isEcoProd }
        this.$axios.post('../api/prod/openEcoSeat', params)
          .then((res) => {
            if (res.data.rs === 'success') {
              this.$alert("車聯網連線測試完成", "結果", { confirmButtonText: "確定" });
            } else {
              this.$alert("車聯網連線失敗，請洽管理員", "結果", { confirmButtonText: "確定" });
            }
            this.fullscreenLoading = false
          })
      })
    },
    uploadFile(type) {
      let tUploadPath = this.uploadPath + "/" + type;
      let tType = type;
      this.$confirm("是否確定上傳照片", "注意", {
        confirmButtonText: "確定",
        cancelButtonText: "取消"
      }).then(() => {
        this.fullscreenLoading = true;
        this.$axios
          .post(tUploadPath, this.formData)
          .then(res => {
            switch (tType) {
              case "1":
                this.pic1 = "/imgs/zocha/prod/" + res.data.img;
                break;
              case "2":
                this.pic2 = "/imgs/zocha/prod/" + res.data.img;
                break;
              case "3":
                this.pic3 = "/imgs/zocha/prod/" + res.data.img;
                break;
              case "describeImg":
                this.describeImg = "/imgs/zocha/prod/" + res.data.img;
                break;
              case "officeImg":
                this.officeImg = "/imgs/zocha/office_prod/" + res.data.img;
                break;
            }
            this.fullscreenLoading = false;
          })
          .catch(res => {
            this.fullscreenLoading = false;
            console.log(res);
          });
      });
    },
    async fileChange(e) {
      this.formData = new FormData();
      //放進上傳的檔案
      try {
        const compressedFile = await imageCompression(
          e.target.files[0],
          this.imageOption
        );
        this.formData.append("file", compressedFile);
      } catch (error) {
        console.log(error);
      }
    },
    backUploadDialog() {
      this.dialogFormVisible = true
      this.dialogPicVisible = false
      this.query();
    },
    handlePictureCardPreview1(file) {
      this.pic1 = "/imgs/zocha/prod/" + file.img;
    },
    handlePictureCardPreview2(file) {
      this.pic2 = "/imgs/zocha/prod/" + file.img;
    },
    handlePictureCardPreview3(file) {
      this.pic3 = "/imgs/zocha/prod/" + file.img;
    },
    uploadFinish() {
      this.$refs.upload1.clearFiles();
    },
    query() {
      this.fullscreenLoading = true
      this.$axios
        .get("../api/prod/getProds", {
          params: {
            page: this.pageCurrent,
            qStoreId: this.qStoreId,
            qCatId: this.qCatId,
            qCarNo: this.qCarNo,
            qEngineId: this.qEngineId,
            qEcoAuto: this.qEcoAuto,
            qIsEcoAuto: this.qIsEcoAuto,
            qProdId: this.qProdId,
            qStatus: this.qStatus,
            qType:this.qType,
            qCarOwner:this.qCarOwner
          }
        })
        .then(res => {
          this.tableData.splice(0, this.tableData.length);
          res.data.data.forEach(prod => {
            if (prod.status == "1") {
              prod.statusType = "";
            } else if (prod.status == "0") {
              prod.statusType = "danger";
            } else if (prod.status == "3") {
              prod.statusType = "warning";
            }
            this.tableData.push(prod);
          });

          if (this.qCarNo != "" && this.tableData.length == 1) {
            this.qCarNo = "";
            this.showDetailForm(this.tableData[0]);
          }
          if (this.qProdId != "") {
            this.qProdId = "";
            this.showDetailForm(this.tableData[0]);
          }

          this.pageTotal = res.data.pageTotal;
          this.fullscreenLoading = false
        })
        .catch(() => {
          this.fullscreenLoading = false
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
        });
    },
    confirmEditStore() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          if (this.form.storeId == "" || this.form.storeId == null) {
            this.$alert("請選擇店家", "結果", { confirmButtonText: "確定" });
            return;
          }
          /*if (this.form.buyDate == "" || this.form.buyDate == null) {
            this.$alert("請輸入購買日期", "結果", { confirmButtonText: "確定" });
            return;
          }
          if (this.form.birthday == "" || this.form.birthday == null) {
            this.$alert("請輸入出廠日期", "結果", { confirmButtonText: "確定" });
            return;
          }*/
          if (this.form.carNoDisName == '' || this.form.carNoDisName == null) {
            this.$alert("請輸入車輛編號", "結果", { confirmButtonText: "確定" });
            return;
          }
          if (this.form.insuranceDueDate == "" || this.form.insuranceDueDate == null) {
            this.$alert("請輸入保險到期日期", "結果", {
              confirmButtonText: "確定"
            });
            return;
          }
          let url = "../api/prod/updateProd";
          let confirmText = "是否確定更新車輛資料";
          if (this.viewMode === "create") {
            this.form.prodId = undefined;
            url = "../api/prod/createProd";
            confirmText = "是否確定新增車輛";
          }

          if (this.form.buyDate instanceof Date) {
            this.form.buyDate = moment(this.form.buyDate).format("YYYY-MM-DD");
          } else {
            this.form.buyDate = null;
          }
          if (this.form.birthday instanceof Date) {
            this.form.birthday = moment(this.form.birthday).format("YYYY-MM-DD");
          } else {
            this.form.birthday = null;
          }
          if (this.form.insuranceDueDate instanceof Date) {
            this.form.insuranceDueDate = moment(this.form.insuranceDueDate).format("YYYYMMDD");
          } else {
            this.form.insuranceDueDate = null;
          }
          if (this.form.lastMaintainDate instanceof Date) {
            this.form.lastMaintainDate = moment(this.form.lastMaintainDate).format("YYYYMMDD");
          } else {
            this.form.lastMaintainDate = null;
          }
          this.$confirm(confirmText, "注意", {
            confirmButtonText: "確定",
            cancelButtonText: "取消"
          }).then(() => {
            this.fullscreenLoading = true;
            this.$axios.post(url, this.form)
              .then(res => {
                if (res.data.rs === "success") {
                  this.fullscreenLoading = false;
                  this.query();
                  this.dialogFormVisible = false;
                  if (this.viewMode == 'update' && this.form.storeId != this.form.storeId_) {
                    this.$alert('門市車輛轉移，請手動更新庫存', "注意", { confirmButtonText: "確定" });
                  }
                } else {
                  this.fullscreenLoading = false;
                  this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
                }
              }).catch(() => {
                this.$alert("修改失敗", "結果", { confirmButtonText: "確定" });
              });
          });
        } else {
          console.log("test");
        }
      });
    },
    showCreateDetailForm() {
      this.disabledButton = true
      this.prodStatus.splice(0, this.prodStatus.length);
      this.viewMode = "create";
      this.form = Object.assign({}, this.form, {
        prodId: '自動產生', // 商品ID
        engineId: '', // 引擎號碼
        storeId: null, // 店家
        carNo: '', // 車號
        carNoDisName: '', // 車輛編號
        status:'0', // 狀態
        carOwner: '', // 車輛所屬
        ccType: '', // 類別
        enabledDistance: true, // 里程管理
        prodCatId:'', //車型
        color: '', // 顏色
        buyDate: new Date(), // 購買日期
        insuranceDueDate: new Date(), // 保險到期日
        buyDistance: null, // 購買里程
        birthday: new Date(), // 出廠日期
        nowDistance: null, // 目前里程
        monthStartDistance: 0, // 本期起始里程
        contractMileage: 0, // 每月內含里程
        contractMonthOfDate: 0, // 每期里程結算日
        deductibleMileage: 0, // 本期可折抵里程
        maintainDistance: 0, // 保養里程
        maintainDays: 0, // 保養周期
        lastMaintainDistance: 0, // 上次保養里程
        lastMaintainDate: null, // 上次保養周期
        newStatus: "", //
        clientOrderId: null, //
        enabledEco:'F', // 啟動無人自助
        ecoAuto:'' // ECO機碼
      });
      this.dialogFormVisible = true;
    },
    showDescribePicForm(prod) {
      this.fullscreenLoading = true
      this.$axios
        .get("../api/prod/getProd?prodId=" + prod.prodId)
        .then(res => {
          let prod_ = res.data.data.prod;
          this.uploadPath = "../api/prod/upload/" + prod_.prodId;
          this.urlDescribeImg =
            "../api/prod/upload/" + prod_.prodId + "/describe";
          if ((prod_.describeImg != undefined) & (prod_.describeImg != null)) {
            this.describeImg = "/imgs/zocha/" + prod_.describeImg;
          } else {
            this.describeImg = "/imgs/zocha/fontprod/none_fontProdPhoto.png";
          }
          this.dialogDescribePicVisible = true;
          this.fullscreenLoading = false
        })
        .catch(res => {
          this.fullscreenLoading = false
          console.log(res);
          this.$alert("login error", "結果", { confirmButtonText: "確定" });
        });
    },
    showPicDetailForm(prod2) {
      this.fullscreenLoading = true
      this.$axios
        .get("../api/prod/getProd?prodId=" + prod2.prodId)
        .then(res => {
          let prod = res.data.data.prod;
          this.uploadPath = "../api/prod/upload/" + prod.prodId;
          this.url1 = "../api/prod/upload/" + prod.prodId + "/1";
          this.url2 = "../api/prod/upload/" + prod.prodId + "/2";
          this.url3 = "../api/prod/upload/" + prod.prodId + "/3";
          this.urlOfficeImg = "../api/prod/upload/" + prod.prodId + "/officeImg";
          if ((prod.pic1 != undefined) & (prod.pic1 != null)) {
            this.pic1 = "/imgs/zocha/" + prod.pic1;
          } else {
            this.pic1 = "/imgs/zocha/prod/none_prodPhoto.png";
          }
          if ((prod.pic2 != undefined) & (prod.pic2 != null)) {
            this.pic2 = "/imgs/zocha/" + prod.pic2;
          } else {
            this.pic2 = "/imgs/zocha/prod/none_prodPhoto.png";
          }
          if ((prod.pic3 != undefined) & (prod.pic3 != null)) {
            this.pic3 = "/imgs/zocha/" + prod.pic3;
          } else {
            this.pic3 = "/imgs/zocha/prod/none_prodPhoto.png";
          }
          if ((prod.officeImg != undefined) & (prod.officeImg != null)) {
            this.officeImg = "/imgs/zocha/" + prod.officeImg;
          } else {
            this.officeImg = "/imgs/zocha/fontprod/none_fontProdPhoto.png";
          }
          this.dialogPicVisible = true;
          this.fullscreenLoading = false
        })
        .catch(res => {
          this.fullscreenLoading = false
          console.log(res);
          this.$alert("login error", "結果", { confirmButtonText: "確定" });
        });
    },
    showDetailForm(prod) {
      this.fullscreenLoading = true
      this.disabledButton = false
      this.viewMode = "update";
      this.$axios
        .get("../api/prod/getProd?prodId=" + prod.prodId)
        .then(res => {
          this.newStatus = "";
          this.dialogFormVisible = true;
          let prod = res.data.data.prod;
          let prodDescribe = res.data.data.prodDescribe;
          this.isEcoProd = res.data.data.isEcoProd;
          this.prodStatus.splice(0, this.prodStatus.length);
          prodDescribe.forEach(e => {
            e.updateDate = this.$date_(e.updateDate);
            this.prodStatus.push(e);
          })
          if (prod.buyDate != null && prod.buyDate.match(/\d{4}-\d{2}-\d{2}/)) {
            prod.buyDate = moment(prod.buyDate).toDate();
          }
          if (prod.birthday != null && prod.birthday.match(/\d{4}-\d{2}-\d{2}/)) {
            prod.birthday = moment(prod.birthday).toDate();
          }
          if (prod.insuranceDueDate != null && prod.insuranceDueDate.match(/\d{4}\d{2}\d{2}/)) {
            prod.insuranceDueDate = moment(prod.insuranceDueDate, "YYYYMMDD").toDate();
          } else {
            prod.insuranceDueDate = moment().toDate();
          }
          if (prod.lastMaintainDate != null && prod.lastMaintainDate.match(/\d{8}/)) {
            prod.lastMaintainDate = moment(prod.lastMaintainDate, "YYYYMMDD").toDate();
          } else {
            prod.lastMaintainDate = null
          }
          
          this.storeId = prod.storeId;
          if(prod.enabledEco == null) {
            prod.enabledEco = 'F'
          }
          this.form = Object.assign({}, prod);
          this.form.storeId_ = this.form.storeId
          this.fullscreenLoading = false
        })
        .catch(() => {
          this.fullscreenLoading = false
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
        });
    },
    deleteRow(index) {
      console.log(index);
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined) return "";
      return this.$storeDisName(this.$store.state.stores, storeId);
    },
    fter_cat(row, column) {
      let prodCatId = row[column.property];
      if (prodCatId == undefined) return "";
      return this.$catDisName(this.$store.state.cats, prodCatId);
    },
    checkCarNo() {
      this.fullscreenLoading = true
      this.form.carNo = this.form.carNo.trim().toUpperCase()
      this.$axios
        .get("../api/prod/checkCarNo?carNo=" + this.form.carNo, {})
        .then(res => {
          if ((res.data.carNo != undefined) & (this.form.prodId == "自動產生")) {
            this.$alert("車號重覆", "結果", { confirmButtonText: "確定" });
            this.form.carNo = "";
          } else {
            this.form.carNoDisName = this.form.carNo
          }
          this.fullscreenLoading = false
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert("車號重覆", "結果", { confirmButtonText: "確定" });
        });
    },
    checkCarNoDisName() {
      this.fullscreenLoading = true
      this.form.carNoDisName = this.form.carNoDisName.trim().toUpperCase()
      this.$axios
        .get("../api/prod/checkCarNoDisName", {params:{carNo: this.form.carNo, carNoDisName: this.form.carNoDisName, storeId: this.form.storeId}})
        .then(res => {
          if (res.data.carNo != undefined) {
            this.$alert("車輛編號重覆", "結果", { confirmButtonText: "確定" });
            this.form.carNoDisName = "";
          }
          this.fullscreenLoading = false
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert("車輛編號重複", "結果", { confirmButtonText: "確定" });
        });
    },
    showProdsDistanceform() {
      this.fullscreenLoading = true
      this.dialogProdsDistanceVisible = true
      this.carNoDistanceInput = ""
      this.$axios.get("../api/prod/getCarNosForEditDistance", { params: {qStoreId: this.qStoreId}})
        .then((res)=>{
            this.carNoList = res.data.carNos
            this.fullscreenLoading = false
          })
    },
    showProdsMileageform() {
      this.fullscreenLoading = true
      this.dialogProdsMileageVisible = true
      this.carNoMileageInput = ""
      this.$axios.get("../api/prod/getCarNosForEditDistance", { params: {qStoreId: this.qStoreId}})
        .then((res)=>{
            this.carNoList = res.data.carNos
            this.fullscreenLoading = false
          })
    },
    confirmProdsDistance() {
      let dataSlot = []
      let errorCarNos = []
      let textLine = this.carNoDistanceInput.split("\n")
      textLine.forEach( string => {
        if (string !== '' && string !== null) {
          let content = string.split("\t")
          if(this.carNoList.indexOf(content[0]) != -1 && content[1].match(/\d+/) ) {
            dataSlot.push({carNo: content[0],distance: content[1]})
          } else {
            errorCarNos.push(content[0])
          }
        }
      })
      let confirmMessage = '是否確定更改車輛里程'
      if(errorCarNos.length > 0) {
        confirmMessage = '車號'+errorCarNos.toString()+'資料錯誤。是否略過？'
      }
      this.$confirm(confirmMessage,'注意',{confirmButtonText:'確定',cancelButtonText:'取消'}).then(()=>{
        this.fullscreenLoading = true
        let param = {
          carNoDistanceList: dataSlot
        };
        this.$axios
          .post("../api/prod/updateProdsDistance", param)
            .then((res) => {
              this.fullscreenLoading = false;
              if (res.data.rs ==="success") {
                this.$alert('更新成功','結果',{confirmButtonText:'確定'})
                this.dialogProdsDistanceVisible = false
                this.query()
              } else {
                this.$alert('更新失敗','結果',{confirmButtonText:'確定'})
              }
          }).catch(() =>{
            this.$alert('發生不知名錯誤','結果',{confirmButtonText:'確定'})
            this.fullscreenLoading = false;
          })
      })
    },
    confirmProdsMileage(){
      let dataSlot = []
      let errorCarNos = []
      let textLine = this.carNoMileageInput.split("\n")
      textLine.forEach( string => {
        if (string !== '' && string !== null) {
          let content = string.split("\t")
          if(this.carNoList.indexOf(content[0]) != -1 && content[1].match(/\d+/) && content[2].match(/\d+/)) {
            dataSlot.push({carNo: content[0],contractMileage: content[1],deductibleMileage: content[2]})
          } else {
            errorCarNos.push(content[0])
          }
        }
      })
      let confirmMessage = '是否確定更改車輛里程'
      if(errorCarNos.length > 0) {
        confirmMessage = '車號'+errorCarNos.toString()+'資料錯誤。是否略過？'
      }
      this.$confirm(confirmMessage,'注意',{confirmButtonText:'確定',cancelButtonText:'取消'}).then(()=>{
        this.fullscreenLoading = true
        let param = {
          carNoDistanceList: dataSlot
        };
        this.$axios
          .post("../api/prod/updateProdsMileage", param)
            .then((res) => {
              this.fullscreenLoading = false;
              if (res.data.rs ==="success") {
                this.$alert('更新成功','結果',{confirmButtonText:'確定'})
                this.dialogProdsMileageVisible = false
                this.query()
              } else {
                this.$alert('更新失敗','結果',{confirmButtonText:'確定'})
              }
          }).catch(() =>{
            this.$alert('發生不知名錯誤','結果',{confirmButtonText:'確定'})
            this.fullscreenLoading = false;
          })
      })
    },
    showKeyBoxForm(prod,opType) {
      this.fullscreenLoading = true
      this.keyBox.carNo = prod.carNo
      this.keyBox.prodId = prod.prodId
      this.keyBox.doorId = opType == 'get' ? prod.keyBoxDoorId : ''
      this.keyBox.opType = opType
      this.keyBox.qrCodeUrl = ''
      this.keyBox.step = 'qrCode'
      this.$axios.get('../api/prod/getStockDoors?storeId='+prod.storeId).then(res => {
        this.notEmptyDoors = []
        if(res.data.length >= 10 && opType == 'put') {
          this.$alert('智取櫃已滿','注意',{confirmButtonText:'確認'})
          return
        } else {
          this.$axios.get('../api/prod/getQRCodeForKeyBox').then((res) => {
            if (res.data.rs == 'success') {
              this.keyBox.clientId = res.data.data.client_id
              this.keyBox.qrCodeUrl = '../api/prod/getKeyBoxQrCode?url=' + btoa(res.data.data.qr_string)
              this.dialogKeyBoxVisible = true
            } else {
              this.$alert(res.data.rsMsg,'注意',{confirmButtonText:'確認'})
            }
          })
        }
        res.data.forEach(e => {
          if(opType == 'put') {
            this.keyBox.doorId = parseInt(e.keyBoxDoorId)
          }
          this.notEmptyDoors.push(parseInt(e.keyBoxDoorId))
        })
        this.fullscreenLoading = false
      })
    },
    getQRCodeForKeyBox() {
      this.fullscreenLoading = true
      this.$axios.get('../api/prod/getQRCodeForKeyBox').then((res) => {
        if (res.data.rs == 'success') {
          this.keyBox.clientId = res.data.data.client_id
          this.keyBox.qrCodeUrl = '../api/prod/getKeyBoxQrCode?url=' + btoa(res.data.data.qr_string)
        } else {
          this.$alert(res.data.rsMsg,'注意',{confirmButtonText:'確認'})
        }
        this.fullscreenLoading = false
      })
    },
    confirmQRCodeScan(){
      this.keyBox.step = 'door'
      // 取得
      
    },
    keyBoxOpenDoor(){
      this.fullscreenLoading = true
      this.$axios.post('../api/prod/keyBoxOpenDoor',this.keyBox).then(res => {
          if(res.data.rs == 'success') {
            this.query()
          } else if (res.data.rs == 'fail'){
            this.$alert('智取櫃儲格異常，重新取得qrcode再掃碼開關門。如持續失敗聯絡請聯絡管理者','注意',{confirmButtonText:'確認'})
          } else {
            this.$alert(res.data.rsMsg,'注意',{confirmButtonText:'確認'})
          }
          this.fullscreenLoading = false
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert('不知名錯誤','注意',{confirmButtonText:'確認'})
        })
    },
    noCarOwner() {
      this.form.carOwner=''
    },
    displayCarOwner(carOwner) {
      switch (carOwner) {
        case 'zocha':
          return '摩拓自有';
        case 'cooperate':
          return '合作門市';
        case 'investor':
          return '投資人';
      }
    },
    downloadExcel () {  
      if (this.qCarNo != "" && this.tableData.length == 1) {
          this.qCarNo = "";
          this.showDetailForm(this.tableData[0]);
        }
        const params = new URLSearchParams({
          qStoreId: this.qStoreId, // 所屬店家
          qCarNo: this.qCarNo, // 車號
          qEngineId: this.qEngineId, // 引擎號碼
          qEcoAuto: this.qEcoAuto, // ECO碼
          qIsEcoAuto: this.qIsEcoAuto, // 有無ECO瑪
          qCatId: this.qCatId, // 車型
          qStatus: this.qStatus, // 狀態
          qCarOwner: this.qCarOwner // 車輛所屬
        });
      window.open(`../api/prod/downloadExcel?${params.toString()}`);
    },
    resetQuery(){
      this.qStoreId = '',
      this.qCarNo = '',
      this.qEngineId = '',
      this.qEcoAuto = '',
      this.qIsEcoAuto = '',
      this.qCatId = this.qCats[0].code,
      this.qStatus = '',
      this.qCarOwner = ''
    },
    changeIotType() {
      switch (this.form.iotType) {
        case 'ECO':
          break;
        case 'PURISM':
          this.form.enabledEco = 'F'
          this.form.ecoAuto = null
          break;
      
        default:
          this.form.enabledEco = 'F'
          this.form.ecoAuto = null
          break;
      }
    }
  }
}
</script>

<style scoped>

.pic {
  width: 600px;
  height: 450px;
}
.remindText{
  color:rgb(210, 28, 28);
  font-size:medium;
  font-weight: bold;
}
</style>
