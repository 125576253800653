<template>
 <div v-loading.fullscreen.lock="fullscreenLoading">
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="" v-model="qCode">
          <template slot="prepend">優惠券</template>
        </el-input>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="" v-model="qDisName">
          <template slot="prepend">名稱</template>
        </el-input>
      </el-col>
      <el-col :span="7">
        店家&nbsp;&nbsp;<el-select v-model="qStoreId" filterable>
          <el-option 
            v-for="item in storeIds"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-checkbox-group v-model="qCheckListCount" style="margin-top:1vh;">
          <el-checkbox :label="qCheckListCount_1"></el-checkbox>
          <el-checkbox :label="qCheckListCount_2"></el-checkbox>
        </el-checkbox-group>
        <el-checkbox v-model="checked" :label="qCheckListCount_3"></el-checkbox>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:1vh;">
      <el-col :span="8">
        只適用首次<el-select v-model="qLimitNewClientUser">
          <el-option key="Y" value="Y" label="Y"></el-option>
          <el-option key="N" value="N" label="N"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        使用類型
        <el-select v-model="qUseType">
          <el-option key="ONE" label="單次使用"  value="ONE"></el-option>
          <el-option key="MORE" label="重複使用" value="MORE"></el-option>
        </el-select>
      </el-col>
      <el-col :span="14">          
        領取日期
        <el-date-picker v-model="qStarteDate" type="date" placeholder="起"></el-date-picker> ~
        <el-date-picker v-model="qEndDate" type="date" placeholder="訖"></el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:1vh;">
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="query()">查詢</el-button>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="showCreateDetailForm()" v-if="this.$store.getters.userType!='2'">新增</el-button>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="showBatchSentPromotionform()" v-if="this.$store.getters.userType!='2'">批量發送優惠券</el-button>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="downloadPormotionLogExcel()">下載領取紀錄</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="backDisName" label="優惠卷名稱" width="120"> </el-table-column>
      <el-table-column prop="disName" label="前台呈現名稱" width="120"> </el-table-column>
      <el-table-column prop="code" label="優惠碼" width="100"> </el-table-column>
      <el-table-column prop="restCount" label="剩餘張數" width="100"> </el-table-column>
      <el-table-column prop="type" label="類型" width="80"> </el-table-column>
      <el-table-column prop="discount" label="折扣" width="80"> </el-table-column>
      <el-table-column prop="limitLowerPrice" label="最低消費" width="100"> </el-table-column>
      <el-table-column prop="limitNewClientUser" label="只適用首次" width="100"> </el-table-column>
      <el-table-column prop="disUseType" label="使用類型" width="100"> </el-table-column>
      <el-table-column prop="startDateStr" label="開始日期" width="120"> </el-table-column>
      <el-table-column prop="endDateStr" label="結束日期" width="120"> </el-table-column>
      <el-table-column prop="updateDate" label="更新日期" width="140"> </el-table-column>
      <el-table-column prop="updator" label="更新人" width="120"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="80" v-if="this.$store.getters.userType!='2'">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible" :close-on-click-modal="false" style="width:120vw;">
    <el-form :inline="true" :model="form" ref="editForm">
      <!-- disabled鎖住 readonl只可讀取 -->
      <el-form-item label="類型" :label-width="formLabelWidth">
        <el-select v-model="form.type"  style="width:17vw;">
          <el-option 
            v-for="(item, key) in types"
            :key="key"
            :label="item.disName"
            :value="item.disName"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" :label-width="formLabelWidth">
        <div style="width:17vw;"></div>
      </el-form-item>
      <el-form-item label="最低消費" :label-width="formLabelWidth">
        <el-input v-model.number="form.limitLowerPrice"  style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="折扣金額" :label-width="formLabelWidth" v-show="form.type == '金額'">
        <el-input v-model.number="form.discount"  oninput="value=value.replace(/[^\d]/g, '')" v-show="form.type == '金額'" placeholder="正整數" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="折扣成數" :label-width="formLabelWidth" v-show="form.type == '成數'">
        <el-input v-model.number="form.discount"  @blur="check_num(form.discount)" oninput="value=value.replace(/[^\d]/g, '')" v-show="form.type == '成數'" placeholder="1~99" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="優惠碼名稱" :label-width="formLabelWidth">
        <el-input v-model="form.backDisName" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="前台呈現名稱" :label-width="formLabelWidth">
        <el-input v-model="form.disName" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="優惠碼" :label-width="formLabelWidth">
        <el-input v-model="form.code" :disabled="form.rowId != null" @blur="checkPromotionNo()" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="最初發行數量" :label-width="formLabelWidth">
        <el-input v-model.number="form.orgQty" :disabled="form.rowId != null " style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="目前數量" :label-width="formLabelWidth">
        <el-input v-model.number="form.restCount"  v-show="form.rowId == null" placeholder="等同發行數量" style="width:17vw;"></el-input>
        <el-input v-model.number="form.restCount" oninput="value=value.replace(/[^\d]/g, '')" v-show="form.rowId != null" placeholder="不可大於發行數量" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="開始日期" :label-width="formLabelWidth" >
        <el-date-picker :editable="false" v-model="form.sDateModel" type="date" :clearable="false"  style="width:17vw;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="結束日期" :label-width="formLabelWidth" >
        <el-date-picker :editable="false" v-model="form.eDateModel" type="date" :clearable="false"  style="width:17vw;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="注意事項" :label-width="formLabelWidth" >
        <div style="width:17vw; line-height:22px; margin-top:0px; color:#FF0000;">類型為成數<br/>只可填入1~99 </div>
      </el-form-item>
      <el-form-item label="只適用首次" :label-width="formLabelWidth" >
        <el-select v-model="form.limitNewClientUser" style="width:17vw;">
          <el-option 
            v-for="(item, key) in limitNewClientUsers"
            :key="key"
            :label="item.disName"
            :value="item.disName"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-radio v-model="form.useType" label="MORE" @change.native="changeUseType">會員不須領用並可多次使用</el-radio>
        <el-radio v-model="form.useType" label="ONE" @change.native="changeUseType">會員只可使用一次</el-radio>
      </el-form-item>
      <el-form-item label="適用店家" :label-width="formLabelWidth">
        <el-select v-model="storeId" multiple style="width:35vw; margin-right:5px" :disabled="!(form.rowId == null|| $store.getters.userType ==='0')">
          <el-option 
            v-for="item in allStore"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            v-show="authStoreIds.indexOf(item.code) != -1" 
            >
          </el-option>
        </el-select>
        <el-select v-model="form.storeGroupId" @change="selectStoreGroup" placeholder="群租選擇" style="width: 8vw; margin-right:5px">
          <el-option v-for="item in storeGroupList" 
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            >
          </el-option>
        </el-select>
        <!-- <el-button type="primary" @click="selectAll">全選</el-button> -->
        <el-button type="" @click="selectAllCanel">取消全選</el-button>
      </el-form-item>
      <el-form-item label="自動發送類型" :label-width="formLabelWidth">
        <el-select v-model="form.autoSentType"  style="width:17vw;">
          <el-option label="不發送" value="N" ></el-option>
          <el-option label="等待取車" value="AUTO_SENT_FLOW_50" ></el-option>
          <el-option label="付款完成" value="AUTO_SENT_FLOW_500" ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" :label-width="formLabelWidth">
        <div style="width:17vw;"></div>
      </el-form-item>
      <el-form-item label="自動發送起" :label-width="formLabelWidth" v-show="form.autoSentType != 'N'" >
        <el-date-picker :editable="false" v-model="form.autoSentSDateModel" type="date" style="width:17vw;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="自動發送迄" :label-width="formLabelWidth" v-show="form.autoSentType != 'N'" >
        <el-date-picker :editable="false" v-model="form.autoSentEDateModel" type="date" style="width:17vw;">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button v-show="form.rowId == null" type="primary" @click="confirmEditStore">新 增</el-button>
      <el-button v-show="form.rowId != null" type="primary" @click="confirmEditStore">修 改</el-button>
      <el-button @click="dialogFormVisible = false">取 消</el-button>
    </div>
  </el-dialog>
  <el-dialog title="批量發送優惠卷" :visible.sync="dialogBatchSentPromotionVisible"  width="90%"  :close-on-click-modal="false">
    <el-input type="text" placeholder="優惠碼" v-model="batchPromotionCode"></el-input>
    <el-input type="textarea" placeholder="客戶帳號(請從excel複製帳號再貼上至此)" style="height:50vh"  rows="15" v-model="clientListInput"></el-input>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogBatchSentPromotionVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmBatchSentPromotion()">確 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      rules: {
        disName: [{required: true,message: '不得為空值'}
        ],
        phone: [
          {required: true,message: '不得為空值'}
        ],
        addr: [
          {required: true,message: '不得為空值'}
        ]
      },
      pageTotal: 0,
      pageCurrent: 1,
      url: '',
      labelPosition: 'right',
      qDisName: '',
      qCode: '',
      qStoreId: '',
      qLimitNewClientUser: '',
      qUseType: '',
      storeIds: [], // 搜尋店家選單
      stores: [], // 編輯店家選單
      storeId: [], // 資料店家
      authStoreIds: [],
      allStore: [],
      limitNewClientUsers: [{disName:'N'},{disName:'Y'}], // YorN
      types: [{disName:'金額'},{disName:'成數'}], //類型
      qCheckListCount: ['使用完畢','尚未使用'], // 勾選查詢
      qCheckListCount_1: '使用完畢',
      qCheckListCount_2: '尚未使用',
      checked:false,
      qCheckListCount_3: '已結束',
      tableData: [],
      dialogFormVisible: false,
      fullscreenLoading :false,
      form: {
        rowId: '',
        disName: '',
        code: '',
        type: '',
        discount: 0, // 折扣金額
        limitLowerPrice: 0, // 最低消費
        limitNewClientUser: '', // 適用首次是否
        restCount: 0, // 剩餘張數
        orgQty: 1, // 發行數量
        sDateModel:'', // 開始時間
        eDateModel:'', // 結束時間
        backDisName: '',
        useType: 'ONE',
        updateDate:'',
        updator:'',
        storeGroupId:''//群組店家
      },
      form_:{
        restCount_: null,
        orgQty_: null,
        isPromotionGet: false
      },
      formLabelWidth: '120px',
      dialogBatchSentPromotionVisible: false,
      batchPromotionCode: '',
      clientListInput: '',
      qStarteDate: null,
      qEndDate:null,
      storeGroupList:[]
    }
  },
  async created() {
    this.fullscreenLoading = true
    await this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        if(res.data.authStores.length>=1){
          this.storeIds.push({code:'0',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.storeIds.push(e)
          this.authStoreIds.push(e.code)
          this.stores.push(e)
        })
        this.storeGroupList = [{code:'allStore', disName:'全部'}]
        res.data.storeGroupId.forEach(e => {
          this.storeGroupList.push({
            code: e.code,
            disName: e.disName 
          });
        });
        
        this.qStoreId = this.storeIds[0].code
        this.fullscreenLoading = false
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
        this.fullscreenLoading = false
      });
    await this.$axios.get('../api/adminPromotion/getAllStores').then((res)=>{
      res.data.data.forEach(e => {
        this.allStore.push(e)
      })
      this.query()
    })
  },
  name: 'AdminPromotion',
  components: {},
  computed: {
  },
  methods: {
    query() {
      this.fullscreenLoading = true
      let _qCheckListCount = '清空' //使用完畢or未使用
      if (this.qCheckListCount.length>1) { _qCheckListCount = '' }
      if (this.qCheckListCount.length==1) { _qCheckListCount = this.qCheckListCount[0] }
      if (this.checked == true) { _qCheckListCount += "已結束" }
      let qParams={
        page: this.pageCurrent,
            qDisName: this.qDisName,
            qCode: this.qCode,
            qStoreId: this.qStoreId,
            qCheckListCount: _qCheckListCount,
            qLimitNewClientUser: this.qLimitNewClientUser,
            qUseType: this.qUseType
      }
      this.$axios
        .get('../api/adminPromotion/getPromotions', {
          params: qParams
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(promotion => {
            promotion.updateDate = (promotion.updateDate).substring(0,4)+'/'+(promotion.updateDate).substring(4,6)+'/'+(promotion.updateDate).substring(6,8)+' '+(promotion.updateDate).substring(8,10)+':'+(promotion.updateDate).substring(10,12)+':'+(promotion.updateDate).substring(12,14)
            promotion.startDateStr = this.format_date(promotion.startDate)
            promotion.endDateStr = this.format_date(promotion.endDate)
            promotion.disUseType = promotion.useType === 'MORE'? '重複使用' : '單次使用'
            promotion.restCount = promotion.useType === 'MORE'? '無限制' : promotion.restCount

            if (promotion.type == 'PRICE') {
              promotion.type = '金額'
            } else if (promotion.type == 'PERCEN') {
              promotion.type = '成數'
            }

            this.tableData.push(promotion)
          })
          this.pageTotal = res.data.pageTotal
          this.fullscreenLoading = false
        }).catch((res) => {
          console.log(res.response.status)
          this.fullscreenLoading = false
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    selectStoreGroup(){
      if(this.form.storeGroupId == 'allStore'){
        this.storeId = this.authStoreIds.slice();
      }else{
        this.storeId = []
        this.axios.get('../api/adminPromotion/getSelectStoreGroup', {
          params: {
            storeGroupId: this.form.storeGroupId
          }
        }).then((res) => {
          if(res.data.rs === 'success'){
            res.data.datas.forEach(e =>{
              this.storeId.push(e.storeId) 
            })
          }
        }).catch((error) => {
          console.error("Error fetching store group data: ", error);
        });
      }
    },
    selectAllCanel(){
      this.storeId = [];
      this.form.storeGroupId=''
    },
    confirmEditStore() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          let url = '../api/adminPromotion/updatePromotion'
          if (this.form.rowId == null||this.form.rowId == '自動產生') {
            this.form.rowId = undefined;
            url = '../api/adminPromotion/createPromotion'
          }
          let postData= Object.assign({},this.form)
          if(postData.rowId == undefined) {
            delete postData.rowId
          }
          postData.storeId = this.storeId
          if (postData.type == '金額') {
            postData.type = 'PRICE'
          } else if (postData.type == '成數') {
            postData.type = 'PERCEN'
          }

          postData.startDate=moment(this.form.sDateModel).format('YYYYMMDD')
          postData.endDate=moment(this.form.eDateModel).format('YYYYMMDD')
          postData.autoSentSDate = this.form.autoSentSDateModel ? moment(this.form.autoSentSDateModel).format('YYYYMMDD') : null
          postData.autoSentEDate = this.form.autoSentEDateModel ? moment(this.form.autoSentEDateModel).format('YYYYMMDD') : null

          if(parseInt(postData.startDate) > parseInt(postData.endDate)){
            this.$alert('起始日期不可大於結束日期', '結果', { confirmButtonText: '確定' });
            return
          }else if((postData.restCount) > (postData.orgQty)){
            this.$alert('目前數量不可大於發行數量', '結果', { confirmButtonText: '確定' });
            return
          }else if(this.form.rowId !== undefined & postData.restCount === ''){
            this.$alert('目前數量不可為空', '結果', { confirmButtonText: '確定' });
            return
          }else if(postData.code == ''){
            this.$alert('優惠碼不可為空', '結果', { confirmButtonText: '確定' });
            return
          }else if(postData.backDisName == ''){
            this.$alert('名稱不可為空', '結果', { confirmButtonText: '確定' });
            return
          }else if(postData.disName == ''){
            this.$alert('前台呈現名稱不可為空', '結果', { confirmButtonText: '確定' });
            return
          }else if(postData.limitLowerPrice === ''){
            this.$alert('最低消費不可為空', '結果', { confirmButtonText: '確定' });
            return
          }else if(postData.discount === ''){
            this.$alert('折扣不可為空', '結果', { confirmButtonText: '確定' });
            return
          }

          if (postData.autoSentType != 'N' && postData.useType == 'MORE') {
            this.$alert('重複使用不可自動發送', '結果', { confirmButtonText: '確定' });
            return
          }

          if (this.form_.isPromotionGet){
            this.$confirm('已有客戶領用是否修改','注意',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
            this.fullscreenLoading = true
            this.$axios.post(url, postData)
              .then((res) => {
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
                if(res.data.rs === 'success') {
                  this.query()
                  this.dialogFormVisible = false
                }
                this.fullscreenLoading = false
              }).catch((res) => {
                console.log(res)
                this.fullscreenLoading = false
              })
            }).catch(()=> {
              return
            })
          } else {
            this.$axios
              .post(url,
                postData)
              .then((res) => {
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
                if(res.data.rs === 'success') {
                  this.query()
                  this.dialogFormVisible = false
                }
                this.fullscreenLoading = false
              }).catch((res) => {
                console.log(res)
                this.fullscreenLoading = false
              })

          }
        }
      })
    },
    showCreateDetailForm() {
      this.storeId.splice(0, this.storeId.length)
      const dayPlus = new Date()
      dayPlus.setDate(dayPlus.getDate() + 1)

      this.form = Object.assign({}, this.form, {
        rowId: null,
        type: this.types[0].disName,
        limitLowerPrice: 1,
        discount: 1,
        disName: '',
        code : '',
        orgQty: 1,
        restCount: '',
        sDateModel: new Date(),
        eDateModel: dayPlus,
        sDate: '',
        eDate: '',
        limitNewClientUser: this.limitNewClientUsers[0].disName,
        storeId: '',
        backDisName: '',
        useType: 'ONE',
        autoSentType: 'N',
        autoSentSDateModel: null,
        autoSentEDateModel: null,
        storeGroupId:''
      })
      this.form_.restCount_ = null
      this.form_.orgQty_ = null
      this.form_.isPromotionGet = false
      this.dialogFormVisible = true
    },
    showDetailForm(promotion) {
      this.fullscreenLoading = true
      this.$axios
        .get('../api/adminPromotion/getPromotion?rowId=' + promotion.rowId)
        .then((res) => {
          this.storeId.splice(0, this.storeId.length)
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data.data)
          this.form.sDateModel = moment(this.form.startDate,'YYYYMMDD')
          this.form.eDateModel = moment(this.form.endDate,'YYYYMMDD')
          this.form.autoSentSDateModel = this.form.autoSentSDate ? moment(this.form.autoSentSDate,'YYYYMMDD') : null
          this.form.autoSentEDateModel = this.form.autoSentEDate ? moment(this.form.autoSentEDate,'YYYYMMDD') : null
          this.form_.orgQty_=this.form.orgQty
          this.form_.restCount_=this.form.restCount
          this.form_.isPromotionGet = res.data.isPromotionGet
          this.form.storeGroupId = []
          res.data.storeId.forEach(e=>{
            this.storeId.push(e.storeId)
          })

          if (this.form.type == 'PRICE') {
            this.form.type = '金額'
          } else if (this.form.type == 'PERCEN') {
            this.form.type = '成數'
          }
          // res.data.forEach(store=>{this.tableData.push(store)})

          this.fullscreenLoading = false
          
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    deleteRow(index) {
      console.log(index)
    },
    format_date(val){
      if(val!=null && val.length == 8){
        return val.substring(0,4) + '/' + val.substring(4,6) + '/' + val.substring(6, 8)
      }else{
        return val
      }
    },
    check_num(val){//限制輸入數字英文
       if ( val<0 || val>99){
         this.$alert("數值錯誤", '結果', { confirmButtonText: '確定' });
         this.form.discount = ''
       }
    },
    checkPromotionNo(){     
      this.fullscreenLoading = true 
      this.$axios
        .get('../api/adminPromotion/checkPromotionNo?code=' + this.form.code)
        .then((res)=>{  
          if ( res.data.code != undefined || res.data.code != null ) {
            this.$alert('此優惠碼重複', '結果', { confirmButtonText: '確定' });
            this.form.code = ''
          }
          this.fullscreenLoading = false
        }).catch(()=>{
          console.log('檢查優惠碼是否重複')
        })
    },
    changeUseType() {
      if (this.form.rowId === null) {
        if(this.form.useType === 'ONE') {
          this.form.orgQty = 0
        } else if (this.form.useType === 'MORE') {
          this.form.orgQty = 9999999
        }
      } 
      if (this.form.useType === 'MORE') {
        this.form.limitNewClientUser = 'N'
      }
    },
    downloadExcel () {
          
      //this.form.sDateModel = moment(this.form.startDate,'YYYY-MM-DD')
      //this.form.eDateModel = moment(this.form.endDate,'YYYY-MM-DD')
      //this.form.updateDate = moment(this.form.updateDate,'YYYY-MM-DD')

      if (this.qUseType === 'MORE') {
        this.limitNewClientUser = 'N'
      } 
       window.open('../api/adminPromotion/downloadExcel?qDisName='+ this.qDisName +
            '&qCode=' + this.qCode +
            '&qLimitNewClientUser=' + this.qLimitNewClientUser +
            '&qUseType=' + this.qUseType +
            '&type= ' + this.form.type +
            '&qStoreId=' + this.qStoreId )
    },
    downloadPormotionLogExcel () {
      if (this.qStarteDate == null || this.qEndDate == null) {
        this.$alert('請填入時間區間','注意',{confirmButtonText:'確定'})
        return
      }
      if (this.qUseType === 'MORE') {
        this.limitNewClientUser = 'N'
      } 
      let sDate = moment(this.qStarteDate).format('YYYYMMDD000000')
      let eDate = moment(this.qEndDate).format('YYYYMMDD235959')
       window.open('../api/adminPromotion/downloadPormotionLogExcel?qDisName='+ this.qDisName +
            '&qCode=' + this.qCode +
            '&qSDate=' + sDate +
            '&qEDate=' + eDate +
            '&qLimitNewClientUser=' + this.qLimitNewClientUser +
            '&qUseType=' + this.qUseType +
            '&type= ' + this.form.type +
            '&qStoreId=' + this.qStoreId )
    },
    showBatchSentPromotionform () {
      this.dialogBatchSentPromotionVisible = true
      this.batchPromotionCode = ''
      this.clientListInput = ''
    },
    confirmBatchSentPromotion () {
      let textLine = this.clientListInput.split("\n")
      let confirmMessage = '是否確定批量發送優惠券'
      this.$confirm(confirmMessage,'注意',{confirmButtonText:'確定',cancelButtonText:'取消'}).then(()=>{
        this.fullscreenLoading = true
        let param = {
          code: this.batchPromotionCode,
          clientUserList: textLine
        }
        this.$axios
          .post("../api/adminPromotion/batchSentPromotion", param)
            .then((res) => {
              this.fullscreenLoading = false;
              if (res.data.rs ==="success") {
                this.$alert('更新成功','結果',{confirmButtonText:'確定'})
                this.dialogBatchSentPromotionVisible = false
                this.query()
              } else {
                this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'})
              }
          }).catch(() =>{
            this.$alert('發生不知名錯誤','結果',{confirmButtonText:'確定'})
            this.fullscreenLoading = false;
          })
      })
    }
  }
}
</script>

<style scoped>
</style>
