import { render, staticRenderFns } from "./OnePageGet.vue?vue&type=template&id=10c68d66&scoped=true&"
import script from "./OnePageGet.vue?vue&type=script&lang=js&"
export * from "./OnePageGet.vue?vue&type=script&lang=js&"
import style0 from "./OnePageGet.vue?vue&type=style&index=0&id=10c68d66&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c68d66",
  null
  
)

export default component.exports